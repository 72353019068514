<template>
  <div class="content">
    <div class="info">
      If you have any enquiries, don't hesitate to contact us!
    </div>
    <div class="contact_mail">
      <!-- <img class="icon" src="../assets/icons/ml.png" /> -->
      <a class="icon" href="mailto: Parados.exodus@gmail.com"
        >Parados.exodus@gmail.com</a
      >
    </div>
    <div class="info">And give us a follow on our social media accounts!</div>
    <div class="contact">
      <img class="icon" src="../assets/icons/ig.png" />
      <a
        href="https://www.instagram.com/parados.exodus/?utm_medium=copy_link&fbclid=IwAR23kbsBspJXvltY1_VosTJXVjrk-q382k6b7616h45aZKKe8G75N-Fcf2g"
        >parados.exodus</a
      >
    </div>
    <div class="contact">
      <img class="icon" src="../assets/icons/fb.png" />
      <a href="https://www.facebook.com/Parados-Exodus-103039302184087/"
        >Parados,Exodus</a
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>
